<template>
  <div style="width: 100%;">
    <el-card shadow="hover" :body-style="{padding:'1rem 1rem'}" style="width: 100%;min-height: 35rem">
      <el-table v-loading="loading" :data="list" border style="width: 100%">
        <el-table-column prop="id" label="编号" width="80"></el-table-column>
        <el-table-column prop="uid" label="用户编号" show-overflow-tooltip></el-table-column>
        <el-table-column prop="order_id" label="订单编号" show-overflow-tooltip></el-table-column>
        <el-table-column prop="fee" label="奖励金额" show-overflow-tooltip></el-table-column>
        <el-table-column prop="type" label="奖励类型" show-overflow-tooltip></el-table-column>
        <el-table-column prop="status" :formatter="statusFormatter" label="状态" show-overflow-tooltip></el-table-column>
        <el-table-column prop="fail_log" label="异常记录" show-overflow-tooltip></el-table-column>
        <el-table-column prop="pay_time" label="支付时间" show-overflow-tooltip>
          <template #default="s">{{ s.row.pay_time | date }}</template>
        </el-table-column>
        <el-table-column label="创建时间" show-overflow-tooltip>
          <template #default="s">{{ s.row.created_at | date }}</template>
        </el-table-column>
        <el-table-column label="操作">
          <template #default="s">
            <el-button @click="pay(s.row.id)" v-if="s.row.status === 1" size="mini">打款</el-button>
          </template>
          <template #header>
            <el-button v-if="!searchForm.status" @click="wait" size="mini" type="primary">查看待打款</el-button>
            <el-button v-else @click="searchReset" size="mini" type="primary">查看全部</el-button>
          </template>
        </el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
    </el-card>
  </div>
</template>

<script>

export default {
  name: "list",
  components: {},
  data() {
    return {
      list: [],
      page: 1,
      total: 0,
      loading: false,
      searchForm:{
        status:""
      }
    }
  },
  mounted() {
    this.loadList();
  },
  methods: {
    searchReset(){
      this.searchForm.status = "";
      this.page =1;
      this.list = [];
      this.loadList();
    },
    wait(){
      this.searchForm.status = "wait";
      this.page =1;
      this.list = [];
      this.loadList();
    },
    pay(id){
      this.$api.plugin.shop.awardPay({id}).then(()=>{
        this.$message.success("操作成功");
        this.loadList();
      })
    },
    statusFormatter(item) {
      let str = "";
      switch (parseInt(item.status)) {
        case 1:
          str = "未支付";
          break;
        case 2:
          str = "已支付";
          break
        case 3:
          str = "已结束";
          break;
      }
      return str;
    },
    pageChange(e) {
      this.page = e;
      this.loadList();
    },
    loadList() {
      this.loading = true;
      this.$api.plugin.shop.award({
        page: this.page,
        status:this.searchForm.status
      }).then(res => {
        this.loading = false;
        this.list = res.list;
        this.total = res.total;
      })
    }
  }
}
</script>

<style scoped>

</style>